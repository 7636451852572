<template>
    <div>
        <b-row align-h="center" v-if="state === 'initializing' || state === 'loading'">
            <b-spinner></b-spinner>
        </b-row>
        <b-row v-if="state === 'show'">
            <b-col>
                <b-card>
                    <b-row>
                        <b-col>
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>ACL Create Trigger</h4>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Template Name <span class="text-red">*</span></label>
                            <b-form-input v-model="$v.selectedTrigger.triggerName.$model"
                                          @blur="$v.selectedTrigger.triggerName.$touch()" placeholder="Service Request Template"></b-form-input>
                            <div v-if="$v.selectedTrigger.triggerName.$error" class="text-red font-weight-400 text-left">
                                <p v-if="!$v.selectedTrigger.triggerName.required" >This is a required field</p>
                            </div>
                        </b-col>
                        
                        <b-col cols="4"  class="d-flex align-items-center">
                            <b-form-checkbox
                                value="true"
                                unchecked-value="false" v-model="selectedTrigger.active">Active</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Company</label>
                            <b-input-group>
                                <b-form-input v-model="selectedTrigger.company.description" :disabled="true"></b-form-input>
                                <b-input-group-append>
                                    <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal()">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="4">
                            <label>Client Contact</label>
                            <b-input-group>
                                <b-form-input v-model="userFullname" :disabled="true"></b-form-input>
                                <b-input-group-append>
                                    <b-button size="sm" text="Button" variant="secondary" @click="openClientSearchModal()"
                                    :disabled="selectedTrigger.company.description === null ? true : false">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="4">
                            <label>Location</label>
                            <b-form-select v-model="selectedTrigger.locationId" :disabled="userFullname === null" @change="locationSelected">
                                <b-form-select-option v-for="(item, index) in locationsForUser" :key="index" :value="item.locationId">{{item.locationName}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="state === 'show'">
          <b-col>
            <b-card>
              <b-row class="mt-3">
                <b-col class="px-0">
                  <b-tabs card v-model="tabIndex" fill>
                    <b-tab title="Details" active :title-link-class="linkClass(0)" class="px-0">
                      <detailsView />
                    </b-tab>
                    <b-tab title="Checklists" :title-link-class="linkClass(1)" class="px-0">
                      <checklists />
                    </b-tab>
                    <b-tab title="Schedule" :title-link-class="linkClass(2)" class="px-0">
                      <schedule />
                    </b-tab>
                    <b-tab title="History" :title-link-class="linkClass(3)" class="px-0">
                      <history />
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
              <hr class="mx-3">
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-end">
                    <div>
                      <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                    </div>
                    <div>
                      <b-button variant="primary" squared class="ml-2" @click="saveTrigger()"><b-spinner small v-if="state === 'loading'"></b-spinner> Save</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-modal id="deleteModal" hide-footer hide-header-close title="Delete ACL Trigger">

            <b-row>
                <b-col cols="12">
                    <label class="text-center">Are you sure you want to delete <span class="font-weight-bold text-red text-center">ACL TRIGGER(update this with data)</span>?</label>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center mt-3">
                    <b-button variant="red" @click="hideDeleteModal" squared class="mr-2" >Cancel</b-button>
                    <b-button variant="red" squared >Delete</b-button>
                </b-col>
            </b-row>
            <div class="d-block"></div>
        </b-modal>
        
        <b-modal id="search-company-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="search.name"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearFilter()">Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchCompanies()">Search</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-table striped hover :items="tableCompanyData.dataSource" :fields="tableCompanyData.tableColumns"
                        :busy="tableCompanyData.isLoading" @row-clicked="addCompany" 
                                    :per-page="tableCompanyData.resultsPerPage"
                                    :current-page="tableCompanyData.currentPage" sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                        <b-button @click="addCompany(row.item)" size="sm" class="btn-icon">
                            <b-icon-chevron-right></b-icon-chevron-right>
                        </b-button>
                        </b-row>
                    </template>
                </b-table>
            </b-row>
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableCompanyData.currentPage"
                    :total-rows="companyRows"
                    :per-page="tableCompanyData.resultsPerPage"
                ></b-pagination>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchModal()">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="search-client-modal" hide-footer size="xl" no-header @close="closeClientSearchModal()">
            <div v-if="contactMessage.show === true">
                <b-alert :show="dismissCountDown"
                         dismissible
                         :variant="contactMessage.type"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged">{{contactMessage.message}}</b-alert>
            </div>
                
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="clientSearch.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="clientSearch.surname"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3"/>
            <b-row >
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearClientSearchFilter()">Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchClientList()">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col>
                    <b-table striped hover :items="tableClientCompanyData.dataSource" :fields="tableClientCompanyData.tableColumns"
                            :busy="tableClientCompanyData.isLoading" @row-clicked="addClientToRequest" 
                                    :per-page="tableClientCompanyData.resultsPerPage"
                                    :current-page="tableClientCompanyData.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                            <b-button @click="addClientToRequest(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                            </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-pagination
                    v-model="tableClientCompanyData.currentPage"
                    :total-rows="rows"
                    :per-page="tableClientCompanyData.resultsPerPage"
                ></b-pagination>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-right">
                    <b-button variant="red" class="" squared @click="closeClientSearchModal()">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
// import { bus } from '../../main'
import detailsView from "@/components/aclTriggers/details";
import schedule from "@/components/aclTriggers/schedule";
import checklists from "@/components/aclTriggers/checklists";
import history from "@/components/aclTriggers/history";
import { required } from "vuelidate/lib/validators";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "aclTriggerView",
    components: {
        detailsView,
        schedule,
        checklists,
        history
    },
    data: () => ({
        state: 'initializing',
        tabIndex: 0,
        locationsForUser: [],
        tableClientCompanyData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [ 
                {
                    label: 'Name',
                    key: 'name',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Surname',
                    key: 'surname',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Email',
                    key: 'email',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Contact Number',
                    key: 'phone',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        tableCompanyData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [ 
                {
                    label: 'Company',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        search: {
            name: null
        },
        ogClientList: [],
        contactMessage: {
            message: '',
            type: 'success',
            show: false
        },
        userFullname: null,
        dismissSecs: 10,
        dismissCountDown: 0,
        clientSearch: {
            name: null,
            surname: null
        },
        trigger: {
            client: null,
            templateName: null,
        },
    }),
    created() {
        if (this.selectedTrigger.acltriggerId === null || this.selectedTrigger.acltriggerId === undefined) {
            this.goBackToSearch()
        } else {
            this.setBreadcrumb([
                {
                    text: 'ACL Trigger'
                },
                {
                    text: 'Details'
                },
            ])
            this.getTrigger()
            .then(() => {
                if (this.selectedTrigger.clientContact !== null && this.selectedTrigger.company !== null) {
                    this.userFullname = this.selectedTrigger.clientContact.name + ' ' + this.selectedTrigger.clientContact.surname
                    
                    let request = {
                        companyId: this.selectedTrigger.company.id
                    }
                    this.$store.commit('setSearchLocationByCompanyRequest', request)
                    this.searchLocationByCompany()
                    .then((res) => {
                        this.locationsForUser = res.data
                    })
                 

                    if (this.selectedTrigger.locationId !== null) {
                        //console.log('call this thing callContracts');
                        // bus.$emit('callContracts')
                        this.getContractTypesByLocation()
                        .then(() => {
                        //console.log('test 1: callContracts')
                            // this.contractTypes = response.data
                        })
                    }
                }
                else {
                    this.selectedTrigger.company = {description: null}
                }
                this.state = 'show'
            })
        }
        
    },
    methods:{
        ...mapActions(['getContractTypesByLocation', 'searchLocationByCompany', 'searchCompanies', 'searchClientByCompany', 'getTrigger', 'updateAclTriggers', 'searchContactsRequest']),
        ...mapActions('notificationService', ['addWarningMessage']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        
        clearClientSearchFilter(){
            this.clientSearch.name = null
            this.clientSearch.surname = null
            
            this.tableClientCompanyData.isLoading = true;
            this.tableClientCompanyData.dataSource = this.ogClientList;
            this.tableClientCompanyData.isLoading = false;
        },
        doSearchClientList(){
            this.tableClientCompanyData.isLoading = true;

            let searchName = this.clientSearch.name !== null ? this.clientSearch.name.toLowerCase() : null;
            let searchSurname = this.clientSearch.surname !== null ? this.clientSearch.surname.toLowerCase() : null;
            let newArray = this.ogClientList.filter(function (el) {
                return (
                (el.name !== null ? el.name.toLowerCase().includes(searchName) : false) ||
                (el.surname !== null ? el.surname.toLowerCase().includes(searchSurname) : false)
                );
            });

            this.tableClientCompanyData.dataSource = newArray;
            this.tableClientCompanyData.isLoading = false;
        },
        openSearchModal(){
            this.$root.$emit('bv::show::modal', 'search-company-modal', '#btnShow')
            this.doSearchCompanies()
        },
        closeSearchModal(){
            this.$root.$emit('bv::hide::modal', 'search-company-modal', '#btnShow')
        },
        doSearchCompanies(){
            this.tableCompanyData.isLoading = true
            let request = []
            if(this.search.name !== null){
                request.push({"key":"companyName","value": this.search.name})
            }
            this.$store.commit('setSearchCompaniesRequest', request)
            this.searchCompanies()
            .then((res) => {
                this.tableCompanyData.dataSource = res.data
                this.tableCompanyData.isLoading = false
            })
            .catch(() => {
                this.tableCompanyData.isLoading = false
            })
        },
        clearFilter(){
            this.search = {
                name: null
            }
            this.doSearchCompanies()
        },
        addCompany(rowItem){
            this.selectedTrigger.company = rowItem
            this.userFullname = null
            this.closeSearchModal()
        },
        locationSelected(){
            //console.log('location selected: callContracts');
            // bus.$emit('callContracts')
            
            this.getContractTypesByLocation()
            .then(() => {
            //console.log('test 1: callContracts')
                // this.contractTypes = response.data
            })
        },
        
        openClientSearchModal(){
            this.$root.$emit('bv::show::modal', 'search-client-modal', '#btnShow')
            this.contactValues = {
                name: null,
                surname: null,
                email: null,
                cellNumber: null,
                company: this.selectedTrigger.company,
                location: {locationId: null, locationName: null}, 
                newLocation: {locationName: null, address: null, city: null, country: null, postalCode: null},
                landlineNumber: null,
            }
            this.doSearchByCompany()
        },
        closeClientSearchModal(){
            this.$root.$emit('bv::hide::modal', 'search-client-modal', '#btnShow')
        },
        doSearchByCompany(){
            this.tableClientCompanyData.isLoading = true
            let request = {
                companyID: this.selectedTrigger.company.id
            }
            this.$store.commit('setSearchClientByCompanyRequest', request)
            this.searchClientByCompany()
            .then((res) => {
                this.ogClientList = res.data
                this.tableClientCompanyData.dataSource = res.data
                this.tableClientCompanyData.isLoading = false
            })
            .catch(() => {
                this.tableClientCompanyData.isLoading = false
            })
        },
        addClientToRequest(rowItem){
            this.userFullname = rowItem.name + ' ' + rowItem.surname
            this.selectedTrigger.clientContact = rowItem
            this.selectedTrigger.clientId = rowItem.clientContactId
            this.closeClientSearchModal()
            
            let request = {
                companyId: this.selectedTrigger.company.id
            }
            this.$store.commit('setSearchLocationByCompanyRequest', request)
            this.searchLocationByCompany()
            .then((res) => {
                this.locationsForUser = res.data
            })
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['bg-primary', 'text-white']
            } else {
                return ['text-black-50']
            }
        },
        doValidation(){

            console.log('Selected:')
                    console.log(this.selectedTrigger);

            debugger;
            if (this.selectedTrigger.triggerName === null || this.selectedTrigger.triggerName === '' ||
                this.selectedTrigger.ticketSatusId === null || 
                this.selectedTrigger.callTypeId === null ||
                this.selectedTrigger.contractTypeId === null || 
                this.selectedTrigger.priorityId === null ||
                this.selectedTrigger.scheduleTypeId === null ||
                this.selectedTrigger.requestTypeId === null)  {
                this.addWarningMessage('Required fields not entered. Please fill in all fields marked with a *')
                return false
            }
            else{
                return true
            }
        },
        saveTrigger(){
                this.selectedTrigger.requestTypeId = this.secondaryRequestTypes[this.secondaryRequestTypes.length-1].value
            let checkEnteredValues = this.doValidation()
            if (checkEnteredValues === true) {
                this.state = 'loading'
                this.updateAclTriggers()
                .then(() => {
                    this.state = 'show'
                    this.$router.back()
                })
            }
        },
        doSearchClients(){
            this.tableClientData.isLoading = true
            let request = []
            
            if (this.contactSearch.name != null){
                request.push({"key": "name", "value": this.contactSearch.name})
            }
            if (this.contactSearch.surname != null){
                request.push({"key": "surname", "value": this.contactSearch.surname})
            }
            if (this.contactSearch.email != null){
                request.push({"key": "email", "value": this.contactSearch.email})
            }
            
            this.$store.commit('setContactSearchRequest', request)
            
            this.searchContactsRequest()
            .then((response) => {
                this.tableClientData.dataSource = response.data
                this.tableClientData.isLoading = false
            })
            .catch( () => {
                this.tableClientData.isLoading = false
            })
        },
        selectClient(clientSelected){
            this.selectedTrigger.clientId = clientSelected.clientContactId
            this.selectedTrigger.client = clientSelected
            this.hideSearchModal()
        },

        openDeleteModal() {
            this.$bvModal.show('deleteModal')
        },
        hideDeleteModal() {
            this.$bvModal.hide('deleteModal')
        },
        goBackToSearch(){
            this.$router.back()
        },
    },
    computed:{
        ...mapState(['selectedTrigger', 'secondaryRequestTypes']),
        rows() {
          return this.tableClientCompanyData.dataSource.length
      },
      companyRows(){
        return this.tableCompanyData.dataSource.length
      }
    },
    validations: {
        selectedTrigger: {
            triggerName: {required}
        },
    },
}
</script>

<style scoped>

</style>
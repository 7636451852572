<template>
    <div>
        <b-row class="mt-3">
            <b-col cols="4">
                <h6 class="font-weight-bold px-3">
                    Trigger History
                </h6>
                <b-table striped hover
                         :items="tableTriggerData.dataSource"
                         :fields="tableTriggerData.tableColumns"
                         :busy="tableTriggerData.isLoading"
                         :per-page="tableTriggerData.resultsPerPage"
                         id="aclHistory"
                         :current-page="tableTriggerData.currentPage"
                         sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(date)="row">
                        {{row.item.date | dateTimeFilter}}
                    </template>
                </b-table>

            </b-col>
            <b-col cols="8">
                <h6 class="font-weight-bold px-3">
                    Activity on ACL
                </h6>
                <b-table striped hover
                         :items="tableData.dataSource"
                         :fields="tableData.tableColumns"
                         :busy="tableData.isLoading"
                         :per-page="tableData.resultsPerPage"
                         id="aclHistory"
                         :current-page="tableData.currentPage"
                         sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(changeDate)="row">
                        {{row.item.changeDate | dateTimeFilter}}
                    </template>
                    <template #cell(description)="row">
                        <div v-for="(rowItem, index) in row.item.items" :key="index">
                            <span class="bold">{{rowItem.field}}:</span> 
                            {{rowItem.oldValue}} > {{rowItem.newValue}}
                        </div>
                    </template>


                    <template >
                        <b-row align-v="center" align-h="end">
                            <b-button size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                            </b-button>
                        </b-row>
                    </template>
                </b-table>
                <b-row align-h="center">
                    <b-pagination
                        v-model="tableData.currentPage"
                        :total-rows="rows"
                        :per-page="tableData.resultsPerPage"
                        aria-controls="aclHistory"
                    ></b-pagination>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import { required, email, minLength, maxLength, numeric } from "vuelidate/lib/validators";
export default {
    name: "history",
    data: () => ({
        tableData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Date and Time',
                    key: 'changeDate',
                    sortable: true,
                    tdClass: '',
                },
                {
                    label: 'Edited by',
                    key: 'changedBy',
                    sortable: true,
                    tdClass: '',
                },
                {
                    label: 'Activity',
                    key: 'description',
                    sortable: true,
                    tdClass: '',
                },
            ],
        },
        tableTriggerData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns:[
                {
                    label: 'Date and Time',
                    key: 'date',
                    sortable: true,
                    tdClass: ''
                }],
        },
    }),
    created() {
        this.tableData.dataSource = this.selectedTrigger.history.changes
        this.tableData.isLoading = false
        let newHistory = this.selectedTrigger.history.runtimes.map(v => ({
            date: v
                }))
        this.tableTriggerData.dataSource = newHistory
        this.tableTriggerData.isLoading = false
    },
    methods: {
        goBackToSearch(){
            this.$router.back()
        },
        doDescription(){}
    },
    computed: {
        ...mapState(['selectedTrigger']),
        rows() {
            return this.tableData.dataSource.length
        },
    },
    validations:{},
}
</script>

<style scoped>

</style>